<template>
  <page-header-wrapper>
    <a-card :bordered="false">
<!--  产品    -->
      <a-card title="Product Option" style="width: 100%">
        <a-row type="flex" justify="space-between">
          <a-col :span="5">
            <a-card title="Business Type" style="width: 100%">
              <a-row :gutter="10" v-for="(item,index) in BusinessTypeList" :key="index" style="margin-bottom: 10px">
                <a-col :span="16">
                  <a-input style="width: 100%;" disabled v-model="item.name" @pressEnter="pressEnter(item)" />
                </a-col>
<!--                <a-col :span="8">-->
<!--                  <a-row type="flex" justify="space-around" align="middle">-->
<!--                    <a-col :span="4" v-hasPermi="['cigarette:config:edit']">-->
<!--                      <a-icon type="edit" theme="twoTone" style="font-size: 28px;" />-->
<!--                    </a-col>-->
<!--                    <a-col :span="4" v-hasPermi="['cigarette:config:status']">-->
<!--                      <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />-->
<!--                      <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />-->
<!--                    </a-col>-->
<!--                  </a-row>-->
<!--                </a-col>-->
              </a-row>
<!--              <a-button type="link" size="small" @click.stop="addConfigData(0)"  v-hasPermi="['cigarette:config:add']">-->
<!--                +ADD-->
<!--              </a-button>-->
            </a-card>
          </a-col>

          <a-col :span="5">
            <a-card title="Product Type" style="width: 100%">
              <a-row :gutter="10" v-for="(item,index) in ProductTypeList" :key="index" style="margin-bottom: 10px">
                <a-col :span="16">
                  <a-input style="width: 100%;" disabled v-model="item.name" @pressEnter="pressEnter(item)" />
                </a-col>
<!--                <a-col :span="8">-->
<!--                  <a-row type="flex" justify="space-around" align="middle">-->
<!--                    <a-col :span="4" v-hasPermi="['cigarette:config:edit']">-->
<!--                      <a-icon type="edit" theme="twoTone" style="font-size: 28px;" />-->
<!--                    </a-col>-->
<!--                    <a-col :span="4" v-hasPermi="['cigarette:config:status']">-->
<!--                      <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />-->
<!--                      <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />-->
<!--                    </a-col>-->
<!--                  </a-row>-->
<!--                </a-col>-->
              </a-row>
<!--              <a-button type="link" size="small" @click.stop="addConfigData(6)"  v-hasPermi="['cigarette:config:add']">-->
<!--                +ADD-->
<!--              </a-button>-->
            </a-card>
          </a-col>
          <a-col :span="5">
            <a-card title="Tank Size" style="width: 100%">
              <a-row :gutter="10" v-for="(item,index) in TankSizeList" :key="index" style="margin-bottom: 10px">
                <a-col :span="16">
                  <a-input style="width: 100%;" v-model="item.name" @pressEnter="pressEnter(item)" />
                </a-col>
                <a-col :span="8">
                  <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="4"  v-hasPermi="['cigarette:config:edit']">
                      <a-icon type="edit" theme="twoTone" style="font-size: 28px;" />
                    </a-col>
                    <a-col :span="4" v-hasPermi="['cigarette:config:status']">
                      <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />
                      <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-button type="link" size="small"  v-hasPermi="['cigarette:config:add']" @click.stop="addConfigData(1)">
                +ADD
              </a-button>
            </a-card>
          </a-col>
          <a-col :span="5">
            <a-card title="Other Option" style="width: 100%">
              <a-row :gutter="10" v-for="(item,index) in OtherOptionList" :key="index" style="margin-bottom: 10px">
                <a-col :span="16">
                  <a-input style="width: 100%;" v-model="item.name" @pressEnter="pressEnter(item)" />
                </a-col>
                <a-col :span="8">
                  <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="4"  v-hasPermi="['cigarette:config:edit']">
                      <a-icon type="edit" theme="twoTone" style="font-size: 28px;" />
                    </a-col>
                    <a-col :span="4" v-hasPermi="['cigarette:config:status']">
                      <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />
                      <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-button type="link" size="small"  v-hasPermi="['cigarette:config:add']" @click.stop="addConfigData(2)">
                +ADD
              </a-button>
            </a-card>
          </a-col>
          <a-col :span="6">
          </a-col>
        </a-row>
      </a-card>
<!--   产品   -->

<!--      口味-->
      <a-card title="Flavor Option" style="width: 100%;margin-top: 20px;">
        <a-row type="flex" justify="space-between">
          <a-col :span="5">
            <a-card title="Ingredients Type" style="width: 100%">
              <a-row :gutter="10" v-for="(item,index) in IngredientsTypeList" :key="index" style="margin-bottom: 10px">
                <a-col :span="16">
                  <a-input style="width: 100%;" v-model="item.name" @pressEnter="pressEnter(item)" />
                </a-col>
                <a-col :span="8">
                  <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="4"  v-hasPermi="['cigarette:config:edit']">
                      <a-icon type="edit" theme="twoTone" style="font-size: 28px;" />
                    </a-col>
                    <a-col :span="4" v-hasPermi="['cigarette:config:status']">
                      <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />
                      <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-button type="link"  v-hasPermi="['cigarette:config:add']" size="small" @click.stop="addConfigData(3)">
                +ADD
              </a-button>
            </a-card>
          </a-col>
          <a-col :span="5">
            <a-card title="Terpenes Type" style="width: 100%">
              <a-row :gutter="10" v-for="(item,index) in TerpenesTypeList" :key="index" style="margin-bottom: 10px">
                <a-col :span="16">
                  <a-input style="width: 100%;" v-model="item.name" @pressEnter="pressEnter(item)" />
                </a-col>
                <a-col :span="8">
                  <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="4"  v-hasPermi="['cigarette:config:edit']">
                      <a-icon type="edit" theme="twoTone" style="font-size: 28px;" />
                    </a-col>
                    <a-col :span="4" v-hasPermi="['cigarette:config:status']">
                      <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />
                      <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-button type="link" size="small"  v-hasPermi="['cigarette:config:add']" @click.stop="addConfigData(4)">
                +ADD
              </a-button>
            </a-card>
          </a-col>
          <a-col :span="5">
            <a-card title="Usage Effects Tags" style="width: 100%">
              <a-row :gutter="10" v-for="(item,index) in UsageEffectsTagsList" :key="index" style="margin-bottom: 10px">
                <a-col :span="16">
                  <a-input style="width: 100%;" v-model="item.name" @pressEnter="pressEnter(item)" />
                </a-col>
                <a-col :span="8">
                  <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="4"  v-hasPermi="['cigarette:config:edit']">
                      <a-icon type="edit" theme="twoTone" style="font-size: 28px;" />
                    </a-col>
                    <a-col :span="4" v-hasPermi="['cigarette:config:status']">
                      <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;" />
                      <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;" />
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-button type="link"  v-hasPermi="['cigarette:config:add']" size="small" @click.stop="addConfigData(5)">
                +ADD
              </a-button>
            </a-card>
          </a-col>
          <a-col :span="6">
          </a-col>
        </a-row>
      </a-card>
<!--    口味  -->

<!--      轮播图 start-->
      <a-card title="Factory Banner" style="width: 100%;margin-top: 20px;">
        <a-upload
          name="file"
          :multiple="true"
          :show-upload-list="false"
          :action="tupianshangchuanDizhi"
          :headers="headers"
          @change="handleChange($event)"
          v-hasPermi="['cigarette:config:add']"
        >
          <a-button type="primary" style="margin-right: 10px;" >Upload Picture</a-button>Recommended size of picture:686 pixels * 268 pixels, supporting JPG and PNG formats
        </a-upload>

        <a-row :gutter="[16,16]" style="margin-top: 10px;">
          <a-col :span="4" v-for="(item,index) in bannerList">
            <div style="width: 100%;height: 125px;position: relative">
              <img :src="item.name" style="width: 100%;height: 125px;position: absolute" />
              <a-icon type="play-circle" theme="twoTone" @click.stop="changeStatus(item,1)" v-if="item.status === 0" style="font-size: 28px;position: absolute;right: 5px;top: 5px;" />
              <a-icon type="pause-circle" theme="twoTone" @click.stop="changeStatus(item,0)" v-else style="font-size: 28px;position: absolute;right: 5px;top: 5px;" />
            </div>
          </a-col>
        </a-row>
      </a-card>
<!--    轮播图 end  -->

<!--      工厂端使用规则 start-->
      <a-card title="Operating instructions" style="width: 100%;margin-top: 20px;">
        <editor v-model="operatingInstructions.name" />

        <a-button type="primary" style="margin-top: 10px;" @click.stop="pressEnter(operatingInstructions)">Save</a-button>
      </a-card>
<!--      工厂端使用规则 end-->

    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageConfig,listConfig, delConfig,addConfig , updateConfig } from '@/api/cigarette/config'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {tupianshangchuanDizhi} from "@/utils/request";
import Editor from '@/components/Editor'
export default {
  name: 'Config',
  components: {
    CreateForm,
    Editor
  },
  mixins: [tableMixin],
  data () {
    return {
      tupianshangchuanDizhi:tupianshangchuanDizhi,
      list: [],
      BusinessTypeList: [],
      TankSizeList: [],
      OtherOptionList: [],
      IngredientsTypeList: [],
      TerpenesTypeList: [],
      ProductTypeList: [],
      UsageEffectsTagsList: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        type: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '${comment}',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '品牌名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '类型:0-业务类型(business_type),1-油箱尺寸(tank_size),2-其他设置(other_option),3-原料(ingredients_type),4-萜烯(terpenes_type),5-使用效果标签(usage_effects_tags)',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态:0-禁用 1-开启',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      headers:{},
      bannerList: [],
      operatingInstructions:{}
    }
  },
  filters: {
  },
  created () {
    this.headers = {
      "Authorization" : "Bearer " + this.$store.getters.token
    }
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleChange(e,item) {
      console.log(e)
      console.log(item);
      e.file.response.data
      addConfig({
        type:7,
        status:1,
        name:e.file.response.data
      }).then(res => {
        this.bannerList.push(res.data);
      })
      // if(item.url) {
      //   item.url +=(',' + e.file.response.data)
      // } else {
      //   item.url = e.file.response.data
      // }
      // e.file.response.data
    },
    pressEnter(item) {
      console.log('按下回车')
      updateConfig(item).then(res => {

      })
    },
    changeStatus(item,status) {
      let newItemData = JSON.parse(JSON.stringify(item));
      newItemData.status = status;
      updateConfig(newItemData).then(res => {
        if(res.success) {
          this.$set(item,'status',status);
        }
      })
    },
    /* 添加数据*/
    addConfigData(num) {
      if(num === 0) {
        if(this.BusinessTypeList.length) {
          if(this.BusinessTypeList[(this.BusinessTypeList.length - 1)].name) {

          } else {
            this.$message.info('The previous name cannot be empty')
          }
        }
      } else if(num === 1) {
        if(this.TankSizeList.length) {
          if(this.TankSizeList[(this.TankSizeList.length - 1)].name) {

          } else {
            this.$message.info('The previous name cannot be empty')
          }
        }
      } else if(num === 2) {
        if(this.OtherOptionList.length) {
          if(this.OtherOptionList[(this.OtherOptionList.length - 1)].name) {

          } else {
            this.$message.info('The previous name cannot be empty')
          }
        }
      } else if(num === 3) {
        if(this.IngredientsTypeList.length) {
          if(this.IngredientsTypeList[(this.IngredientsTypeList.length - 1)].name) {

          } else {
            this.$message.info('The previous name cannot be empty')
          }
        }
      } else if(num === 4) {
        if(this.TerpenesTypeList.length) {
          if(this.TerpenesTypeList[(this.TerpenesTypeList.length - 1)].name) {

          } else {
            this.$message.info('The previous name cannot be empty')
          }
        }
      } else if(num === 5) {
        if(this.UsageEffectsTagsList.length) {
          if(this.UsageEffectsTagsList[(this.UsageEffectsTagsList.length - 1)].name) {

          } else {
            this.$message.info('The previous name cannot be empty')
          }
        }
      } else if(num === 6) {
        if(this.ProductTypeList.length) {
          if(this.ProductTypeList[(this.ProductTypeList.length - 1)].name) {

          } else {
            this.$message.info('The previous name cannot be empty')
          }
        }
      }


      addConfig({
        type:num,
        status:1
      }).then(res => {
        if(res.success) {
          if(num === 0) {
            this.BusinessTypeList.push(res.data);
          } else if(num === 1) {
            this.TankSizeList.push(res.data);
          } else if(num === 2) {
            this.OtherOptionList.push(res.data);
          } else if(num === 3) {
            this.IngredientsTypeList.push(res.data);
          } else if(num === 4) {
            this.TerpenesTypeList.push(res.data);
          } else if(num === 5) {
            this.UsageEffectsTagsList.push(res.data);
          } else if(num === 6) {
            this.ProductTypeList.push(res.data);
          }
        }
        console.log(res)
      })
    },
    /** 查询新-用户品牌列表 */
    getList () {

      listConfig({
        type: 0
      }).then(res => {
        if (res.success) {
          this.BusinessTypeList = res.data;
        }
      })

      listConfig({
        type: 1
      }).then(res => {
        if (res.success) {
          this.TankSizeList = res.data;
        }
      })

      listConfig({
        type: 2
      }).then(res => {
        if (res.success) {
          this.OtherOptionList = res.data;
        }
      })

      listConfig({
        type: 3
      }).then(res => {
        if (res.success) {
          this.IngredientsTypeList = res.data;
        }
      })

      listConfig({
        type: 4
      }).then(res => {
        if (res.success) {
          this.TerpenesTypeList = res.data;
        }
      })

      listConfig({
        type: 5
      }).then(res => {
        if (res.success) {
          this.UsageEffectsTagsList = res.data;
        }
      })
      listConfig({
        type: 6
      }).then(res => {
        if (res.success) {
          this.ProductTypeList = res.data;
        }
      })

      listConfig({
        type: 7
      }).then(res => {
        if (res.success) {
          this.bannerList = res.data;
        }
      })

      listConfig({
        type: 8
      }).then(res => {
        if (res.success) {
          this.operatingInstructions = res.data[0];
        }
      })


     //  this.loading = true
     //
     //
     // pageConfig(this.queryParam).then(response => {
     //    this.list = response.data.records
     //    this.total = response.data.total
     //    this.loading = false
     //  })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: undefined,
        type: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delConfig(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('cigarette/public-config/export', {
            ...that.queryParam
          }, `新-用户品牌_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
